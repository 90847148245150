import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '../grid/grid'
import { getSize } from '../grid/helper'
import useViewportSize from '../../utils/useViewportSize'

const LiveTvGrid = ({ livetvRecommendations, fetchLivetvRecommendations }) => {
  const language = 'ar'
  const { width } = useViewportSize()

  useEffect(() => {
    // fetch page zero data
    fetchLivetvRecommendationsData({
      page: 0,
      gridSize: getSize(width)
    })
  }, [])

  const fetchLivetvRecommendationsData = ({
    page = livetvRecommendations.page,
    gridSize
  }) => {
    fetchLivetvRecommendations({
      // start the page from 2 to ignore the home items in recommendations api
      page: page + 2,
      lang: language,
      gridSize,
      gridLength: livetvRecommendations.data.length
    })
  }

  return (
    <Grid
      mode="livetv"
      size={16}
      language={'ar'}
      fetchLiveTvData={fetchLivetvRecommendationsData}
      gridData={livetvRecommendations}
      key={'live-tv-grid'}
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLivetvRecommendations: (payload) => {
      dispatch({ type: 'LIVETV_ARTICLES_REQUESTED', payload })
    }
  }
}

const mapStateToProps = ({ livetvRecommendations }) => {
  return {
    livetvRecommendations
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveTvGrid)
