import React from 'react'
import LiveTvGrid from './livetvGrid'
import { breakpoints } from '../../constants/app'
import useViewportSize from '../../utils/useViewportSize'
import testMobile from '../../utils/testMobile'

import { Container, VideoWrapper, VideoInner } from './livetv.styled'
import LiveTvPlayer from '../liveTvPlayer'
import LiveTVHoc from './liveTvHoc'

const LiveTv = ({ location }) => {
  const { width: screenWidth } = useViewportSize()
  const { state = {} } = location
  const isMobile = testMobile()

  return (
    <>
      <Container>
        <LiveTVHoc
          showPip={screenWidth > breakpoints.m}
          page="livetv"
          internalState={state}
          liveTvComponent={
            <VideoWrapper>
              <VideoInner>
                <LiveTvPlayer
                  showLiveTvInPip={isMobile ? false : true}
                  showSocialShareIcons
                  page="livetv"
                />
              </VideoInner>
            </VideoWrapper>
          }
        />
      </Container>
      <LiveTvGrid />
    </>
  )
}

export default LiveTv
