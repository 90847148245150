import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import LiveTv from '../components/livetv'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const LivePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'تلفزيون الشرق | الشرق للأخبار'
  const seoDescription =
    'بث مباشر لأبرز واَخر الأخبار السياسية والاقتصادية والرياضية والثقافية وأخبار التكنولوجيا من حول العالم، بالإضافة لأحدث المجريات التي تهم المشاهد عبر تلفزيون الشرق للأخبار.'
  const canonicalUrl = `${process.env.GATSBY_BASE_URL}live/`

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout location={location} title={siteTitle} lang={'ar'}>
      <h1 className="customH1">{'تلفزيون الشرق - البث المباشر'}</h1>
      <SEO
        title={seoTitle}
        lang="ar"
        description={seoDescription}
        canonicalUrl={canonicalUrl}
      />
      <LiveTv location={location} />
    </Layout>
  )
}

export default LivePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
